<template>
  <div class="hotRankingPage">
    <HeadBar titleName="热门排行榜"></HeadBar>
    <van-tabs
      class="navTabs oepnX"
      swipeable
      animated
      line-width="24"
      line-height="6"
      v-model="activeName"
      background="rgba(0,0,0,0)"
      color="rgb(113,69,231)"
      v-if="navList && navList.length"
      @change="clickTag"
    >
      <van-tab v-for="(item, index) in navList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeName == index }"
          >
            {{ item.title }}
          </span>
        </template>
        <div class="hotRankingContent">
          <PullRefresh
            v-if="!isLoading"
            :loading="loading"
            :refreshing="refreshing"
            :finished="finished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isNoData="isNoData"
            :error="error"
            :is-higeht-max="true"
            :adv-list="advList"
            :hasAdvPagination="true"
            className="HotRankingPage"
          >
            <div class="mirrorContent" v-if="activeName == 2">
              <div
                class="contentItemDiaphragm"
                v-for="(item, index) in videoList"
                :key="index"
                @click="jump(item, index)"
              >
                <div
                  class="videoImg"
                  :class="{
                    videoImgDiaphragm: activeName == 2 || activeName == 3,
                  }"
                >
                  <ImgDecypt :src="item['horizontalCover']" />
                  <div class="mask_h">
                    <div
                      class="maskText"
                      :class="{ updateEnd: item.updateStatus == 2 }"
                    >
                      {{ item.updatedDesc }}
                    </div>
                  </div>
                </div>
                <div class="videoInfo">
                  <div class="videoTitle">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="videoTag">
                    <span
                      v-for="(tagItem, tagIndex) in item['tagNames']"
                      :key="tagIndex"
                      class="videoTxtTag"
                      @click.stop="tagJump(item, tagIndex, tagItem)"
                    >
                      {{ tagItem }}
                    </span>
                  </div>
                  <!-- <div class="videoHot">
                    <img src="@/assets/png/hotFire.png" />
                    <span>{{ sumSli[index] }}</span>
                  </div> -->
                </div>
                <div
                  class="rankNum"
                  :class="
                    index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : index == 3
                  "
                >
                  {{ index > 2 ? index + 1 : "" }}
                </div>
                <!-- <div class="videoRanking">
                  <img src="@/assets/png/rankingOne.png" v-if="index == 0" />
                  <img src="@/assets/png/rankingTwo.png" v-if="index == 1" />
                  <img src="@/assets/png/rankingThree.png" v-if="index == 2" />
                  <span v-if="index > 2 && index < 9"> 0{{ index + 1 }} </span>
                  <span v-if="index > 8">
                    {{ index + 1 }}
                  </span>
                </div> -->
              </div>
            </div>
            <div class="OtherContent" v-else>
              <div
                class="contentItem"
                v-for="(item, index) in videoList"
                :key="index"
                @click="jump(item, index)"
              >
                <div
                  class="videoImg"
                  :class="{
                    videoImgDiaphragm: activeName == 2 || activeName == 3,
                  }"
                >
                  <ImgDecypt :src="item['verticalCover']" />
                  <div class="mask_v">
                    <div
                      class="maskText"
                      :class="{ updateEnd: item.updateStatus == 2 }"
                    >
                      {{ item.updatedDesc }}
                    </div>
                  </div>
                </div>
                <div class="videoInfo">
                  <div class="videoTitle">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="videoTag">
                    <span
                      v-for="(tagItem, tagIndex) in item['tagNames']"
                      :key="tagIndex"
                      class="videoTxtTag"
                      @click.stop="tagJump(item, tagIndex, tagItem)"
                    >
                      {{ tagItem }}
                    </span>
                  </div>
                  <!-- <div class="videoHot">
                    <img src="@/assets/png/hotFire.png" />
                    <span>{{ sumSli[index] }}</span>
                  </div> -->
                </div>
                <div
                  class="rankNum"
                  :class="
                    index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : index == 3
                  "
                >
                  {{ index > 2 ? index + 1 : "" }}
                </div>

                <!-- <div class="videoRanking">
                  <img src="@/assets/png/rankingOne.png" v-if="index == 0" />
                  <img src="@/assets/png/rankingTwo.png" v-if="index == 1" />
                  <img src="@/assets/png/rankingThree.png" v-if="index == 2" />
                  <img src="@/assets/png/rankingFour.png" v-if="index == 3" />
                  <span v-if="index > 3 && index < 9">
                    <div class="">
                      <img src="@/assets/png/rankingFive.png" alt="">
                      {{ index + 1 }}
                    </div>
                  </span>
                  <span v-if="index > 9">
                    {{ index + 1 }}
                  </span>
                </div> -->
              </div>
            </div>
          </PullRefresh>
          <Loading v-else />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { queryLeaderboardList } from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import HeadBar from "@/components/HeadBar";
export default {
  name: "HotRankingPage",
  components: {
    PullRefresh,
    Loading,
    ImgDecypt,
    HeadBar,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      activeName: 0,
      topicId: "",
      zone: "",
      titleName: "",
      videoList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      navList: [
        {
          title: "动漫",
          kind: 0,
        },
        {
          title: "漫画",
          kind: 1,
        },
        {
          title: "真人",
          kind: 3,
        },
        {
          title: "写真",
          kind: 5,
        },
        {
          title: "小说",
          kind: 4,
        },
        {
          title: "原作",
          kind: 6,
        },
      ],
      sumSli: [], //排行榜
    };
  },
  mounted() {
    this.getLeaderboardListInfo();
  },
  methods: {
    async getLeaderboardListInfo(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: this.navList[this.activeName].kind,
      };
      let res = await this.$Api(queryLeaderboardList, req);
      console.log(this.activeName);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      try {
        if (res && res.code == 200) {
          // console.log(res)
          if (res.data.sumSli) {
            this.sumSli = res.data.sumSli;
          }
          if (type == "refresh") {
            this.videoList = res.data.MediaList;
          } else {
            this.videoList = this.videoList.concat(res.data.MediaList);
          }

          if (
            this.pageNumber === 1 &&
            this.videoList &&
            this.videoList.length === 0
          ) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.getLeaderboardListInfo("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getLeaderboardListInfo();
    },
    clickTag(index) {
      if (index != null) {
        this.activeName = index;
      }
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getLeaderboardListInfo("refresh");
    },
    jump(item, index) {
      let kind = item["kind"];
      if (kind != null) {
        switch (kind) {
          case 0:
          case 2:
          case 3:
          case 6:
            //跳转视频
            this.$router.push({
              path: "/videoDetailsPage",
              query: {
                id: item.id,
                kind: kind,
              },
            });
            break;
          case 4:
          case 1:
            //小说/漫画
            this.$router.push({
              path: "/novelDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                price: item.price,
              },
            });
            break;
          case 5:
            console.log(index);
            //图集
            var list = JSON.stringify(this.videoList);
            //图集
            this.$router.push({
              path: "/portrayPage",
              query: {
                id: item.id,
                list: this.encodeBase64(list),
                phoneIndex: index,
              },
            });
            break;
        }
      }
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.hotRankingPage {
  height: 100%;
  /deep/ .headBar {
    .headTitle {
      font-size: 16px;
      color: #333333;
    }
  }
  .navTabs {
    .navTabTitle {
      font-size: 14px;
      color: #999999;
      font-weight: 400;
    }

    .navTabTitleActive {
      font-weight: 600;
      color: #333333;
    }

    ///deep/ .van-tabs__nav--complete {
    //    padding-left: 12px;
    //}
    //
    ///deep/ .van-tabs__wrap--scrollable .van-tab{
    //    padding: 0;
    //    padding-right:21px;
    //}
    //
    /deep/ .van-tabs__wrap {
      height: 44px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
    }

    /deep/ .van-tabs__line {
      width: 18px !important;
      height: 2px !important;
      border-radius: 1px !important;
      background: #94d6da !important;
      bottom: 18px !important;
    }

    /deep/ .van-tab {
      padding: 0;
      width: 40px;
      margin-right: 18px;
    }

    .hotRankingContent {
      height: calc(100vh - 48px - 12px - 44px);
      padding: 0 12px 0 12px;
      overflow-y: auto;

      .mirrorContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
      .contentItemDiaphragm {
        width: 168px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin-bottom: 12px;
        position: relative;

        .videoImg {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 94px;
          border-radius: 8px;
          background: rgb(240, 240, 244);
          // border-radius: 6px;

          // /deep/ .van-image {
          //     border-radius: 6px;
          // }

          // /deep/ .van-image__img {
          //     border-radius: 6px;
          // }
        }

        .videoInfo {
          color: rgb(0, 0, 0);
          // width: calc(100% - 161px - 30.6px);
          width: 100%;
          margin-top: 8px;

          .videoTitle {
            font-size: 12px;
            margin-bottom: 3px;
            font-weight: 400;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .videoTag {
            font-size: 10px;
            color: #4A4A4A;
            margin-bottom: 3px;
            font-weight: 400;

            span {
              margin-right: 4px;
            }
          }

          .videoHot {
            font-size: 12px;
            color: rgb(98, 98, 98);
            display: flex;
            align-items: center;

            img {
              width: 14.8px;
              height: 17px;
              margin-right: 12px;
            }
          }
        }

        // .videoRanking {
        //   width: 30.6px;
        //   font-size: 27px;
        //   color: rgb(142, 142, 144);

        //   img {
        //     width: 30.6px;
        //     height: 30px;
        //   }
        // }
      }
      .rankNum {
          width: 24px;
          height: 24px;
          background-image: url("~@/assets/png/rankingFive.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: rgb(255, 255, 255);
          font-weight: bolder;
          position: absolute;
          left: 0;
          top: 0;
        }

        .rankNum_1 {
          left: 2px;
          top: 3px;
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingOne.png") !important;
          background-size: 100% 100%;
        }

        .rankNum_2 {
          left: 2px;
          top: 3px;
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingTwo.png");
          background-size: 100% 100%;
        }

        .rankNum_3 {
          left: 2px;
          top: 3px;
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingThree.png");
          background-size: 100% 100%;
        }
        .rankNum_4 {
          width: 24px;
          height: 30px;
          background: transparent;
          background-image: url("~@/assets/png/rankingFour.png");
          background-size: 100% 100%;
        }

      .OtherContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
      .contentItem {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        position: relative;
        width: 111px;
        margin-bottom: 12px;
        color: #000000;

        .videoImg {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 162px;
          border-radius: 8px;
          background: rgb(240, 240, 244);
          // border-radius: 6px;

          // /deep/ .van-image {
          //     border-radius: 6px;
          // }

          // /deep/ .van-image__img {
          //     border-radius: 6px;
          // }
        }

        .videoInfo {
          // width: calc(100% - 90px - 30.6px);
          width: 100%;
          margin-top: 8px;

          .videoTitle {
            font-size: 12px;
            margin-bottom: 6px;
            font-weight: 400;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .videoTag {
            font-size: 10px;
            color: #4A4A4A;
            margin-bottom: 12px;
            font-weight: 400;

            span {
              margin-right: 6px;
            }
          }

          .videoHot {
            font-size: 12px;
            color: rgb(98, 98, 98);
            display: flex;
            align-items: center;

            img {
              width: 14.8px;
              height: 17px;
              margin-right: 12px;
            }
          }
        }

        .videoRanking {
          width: 30.6px;
          font-size: 27px;
          color: rgb(142, 142, 144);

          img {
            width: 30.6px;
            height: 30px;
          }
        }
      }
    }
  }

  .content {
    //width: 100%;
    height: calc(100% - 44px);
    padding: 0 12px;
  }

  .mask_h {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 8px;
    .maskText {
      margin: 0 6px 6px 0;
      color: rgba(255, 241, 128, 1);
    }
    .updateEnd {
      color: #fff;
    }
  }
  .mask_v {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 81px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 8px;
    .maskText {
      margin: 0 6px 6px 0;
      color: rgba(255, 241, 128, 1);
    }
    .updateEnd {
      color: #fff;
    }
  }
}
</style>

import axios from "@/utils/request";
const qs = require('qs');

// 获取专区话题媒体
export function queryTopicList(data) {
    return axios.get(`cartoon/zone/topic`, {
        params: data
    })
}

// 获取专区话题更多列表
export function queryTopicMediaList(data) {
    return axios.get(`cartoon/topic/media`, {
        params: data
    })
}


// 获取排行榜
export function queryLeaderboardList(data) {
    return axios.get(`cartoon/play/leaderboard`, {
        params: data
    })
}

// 获取视频分类的Tag列表
export function queryHotkindTagsList(data) {
    return axios.get(`cartoon/hotkind/tags`, {
        params: data
    })
}

// 获取Tag分类视频列表
export function queryTagsmediaList(data) {
    return axios.get(`cartoon/tags/media`, {
        params: data,
        paramsSerializer: function(params) {
            return qs.stringify(params, {indices: false})
        }
    })
}

// 获取作品详情
export function queryMediaInfoList(data) {
    return axios.get(`cartoon/media/info`, {
        params: data
    })
}

// 购买视频
export function videoBuying(data) {
    return axios.post(`/product/buy`, data)
}

// 收藏作品
export function doCollect(data) {
    return axios.post(`cartoon/do/collect`, data)
}

// 取消作品收藏
export function douUcollect(data) {
    return axios.post(`cartoon/do/uncollect`, data)
}

// 获取推荐作品
export function queryRandomInfoList(data) {
    return axios.get(`cartoon/random`, {
        params: data
    })
}

// 获取我的书架
export function queryCollectInfoList(data) {
    return axios.get(`cartoon/collect`, {
        params: data
    })
}
// 获取已购
export function queryBroughtList(data) {
    return axios.get(`cartoon/brought`, {
        params: data
    })
}

// 猜你想看
export function queryHotInfoList(data) {
    return axios.get(`cartoon/hot`, {
        params: data
    })
}

// 搜索推荐关键词
export function querySearchHotWordsList(data) {
    return axios.get(`cartoon/search/hotWords`, {
        params: data
    })
}

// 搜索作品
export function querySearchList(data) {
    return axios.get(`cartoon/search`, {
        params: data
    })
}

// 长视频点赞
export function doLike(data) {
    return axios.post(`cartoon/do/like`, data)
}


// 长视频取消点赞
export function doUnlike(data) {
    return axios.post(`cartoon/do/unlike`, data)
}








